import { Component, OnInit,ViewEncapsulation,ViewChild } from '@angular/core';
import { APIService } from '../services/APIService';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';


import {ActivatedRoute,Router} from '@angular/router';
declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-swagger-ui',
   encapsulation: ViewEncapsulation.None,
  templateUrl: './swagger-ui.component.html',
  styleUrls: ['./swagger-ui.component.css']
})
export class SwaggerUiComponent implements OnInit {

  clientId:any;
  clientSecret:any;
  showError:any=false;
  @ViewChild("dbSelectionModal") dbSelectionModal: ModalDialogComponent;
  id:any=0;
    constructor(public apiService:APIService,public activatedRoute:ActivatedRoute,
   public router:Router) { 
    //  this.id = activatedRoute.params.pipe(map(p => p.id));

   }
   ui:any;
   showTokenWindow() {
        //this.clientSecret='';
        this.showError=false;
        this.dbSelectionModal.show();
   }
   generateToken() {
      this.apiService.getToken(this.clientId,this.clientSecret).subscribe(params=> {
          if(params["token"]) {
            //this.ui.preauthorizeApiKey("ApiKeyAuth","Bearer "+params["token"]);
             this.dbSelectionModal.hide();
             this.loadData(params["token"]);
          } else {
             this.showError=true;
          }
        });
   }
   clearToken() {
      this.clientId='';
      this.clientSecret='';
      this.loadData('');
      this.dbSelectionModal.hide();
   }

   loadData(token) {
        let func=function(abc) {
         // "basicAuth" is the key name of the security scheme in securityDefinitions
           if(token&&token!='')
              this.ui.preauthorizeApiKey("ApiKeyAuth",  "Bearer "+token);
           else
             if(this.apiService.userInfo.admin&&this.apiService.userInfo.admin=='F')
              this.ui.preauthorizeApiKey("ApiKeyAuth",  "Bearer "+this.apiService.token);
         }; 
         func=func.bind(this);
         this.apiService.getSwaggerCatalog(this.id,this.clientId).subscribe(params => {
            params["components"]["securitySchemes"]["ApiKeyAuth"]["type"]='apiKey';
            params["components"]["securitySchemes"]["ApiKeyAuth"]["in"]='header';
           // params["components"]["securitySchemes"]["oAuth2ClientCredentials"]["type"]='OAuth2';
            // temporary patch
            this.ui = SwaggerUIBundle({ dom_id: '#swagger-ui',
                                        layout: 'BaseLayout',
                                        displayRequestDuration:true,
                                        presets: [
                                        SwaggerUIBundle.presets.apis,
                                        SwaggerUIBundle.SwaggerUIStandalonePreset
                                        ],
                                      spec: params,
                    //  url:"http://localhost:8080/test.jsp",
                  onComplete: func,
                    operationsSorter: 'alpha'
              });
              
          });
   }
  ngOnInit(): void {

       this.activatedRoute.params.subscribe(params => {
        this.id=params['id'];
        this.loadData('');
        });
  }
}
