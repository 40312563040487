
<div *ngIf="apiService.show" >

				
<table id="table" width=100% class="table table-sm table-striped table-bordered">
	<tr>
		<th colspan=7>API List
		 <label class="control">Search
            <input type="text" class="input" placeholder="Search on API Name" [(ngModel)]="filter">
        </label>
		</th>
		<div *ngIf="apiService.userInfo.admin=='F'">
		<th align="right">		
		<a href="#" [routerLink]="['/request','Create',0]"  class="btn btn-primary" >Create</a></th>
		</div>
		</tr>
<tr><th>Application</th><th>Category</th><th>Version</th>
	<th>End Point</th><th>API Name</th>
	<th>API Type</th><th>Table Name</th><th>Actions</th></tr>
<tr *ngFor="let api of apiList | stringFilter: filter | paginate: config;index as i">
	<td>{{api.requesterApp}}</td>
	<td>{{api.category}}</td>
	<td>{{api.version}}</td>
	<td>{{api.endPoint}}</td>
	<td>{{api.reportName}}</td>
	<td>{{api.apiType}}</td>
	<td>{{api.request.tableName}}</td>
	<td><a href="#" [routerLink]="['/request','Edit',api.id]"  ><span class="glyphicon glyphicon-pencil" title="Edit"></span></a>&nbsp;
		<a href="#" [routerLink]="['/request','Clone',api.id]"  ><span class="glyphicon glyphicon-copy" title="Clone"></span></a>&nbsp;
		<a href="#" [routerLink]="['/catalog',api.id]"   ><span class="glyphicon glyphicon-eye-open" title="Preview"></span></a>&nbsp;
		<div *ngIf="apiService.userInfo.admin=='F'">
		<a href="#" (click)="deleteAPI(api.id,api.endPoint)"  ><span class="glyphicon glyphicon-trash" title="Delete"></span></a>&nbsp;
		</div>
		</td>
</tr>
</table>
<div class="has-text-centered" style="float: right;">
		    <pagination-controls 
		    	  [maxSize]="maxSize"
				  directionLinks="true"
				  responsive="true"
				  previousLabel="Previous page"
				  nextLabel="Next page"
				  (pageChange)="onPageChange($event)"
				  (pageBoundsCorrection)="onPageChange($event)"
				  ></pagination-controls>
	</div>
</div>
<div *ngIf="!apiService.show">
	<!--	API Token: <input type=text [(ngModel)]="apiService.token">
		<button type="button" class="btn btn-primary" (click)="token()"
								style="margin-right: 5px">Submit</button>

-->
    Invalid User 


</div>