import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app.router';
import { AppComponent } from './app.component';
import { CreateApiComponent } from './create-api/create-api.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { ApiSummaryComponent } from './api-summary/api-summary.component';
import { PreviewApiComponent } from './preview-api/preview-api.component';
import { ModalDialogComponent  } from './modal-dialog/modal-dialog.component';
import { SwaggerUiComponent  } from './swagger-ui/swagger-ui.component';
import { APIService } from './services/APIService';
import { AuthGuard } from './services/AuthGuard';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { NgxPaginationModule } from 'ngx-pagination';
import {StringFilterPipe} from './filter/string-filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    CreateApiComponent,
    AppHeaderComponent,
    ApiSummaryComponent,
    PreviewApiComponent,
    ModalDialogComponent,
    SwaggerUiComponent,
    StringFilterPipe  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    NgxPaginationModule,
  ],
  providers: [   APIService,
                 AuthGuard
            ],
  bootstrap: [AppComponent]
})
export class AppModule { }
 