import { Injectable }       from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { APIService }      from './APIService';
import {ActivatedRoute} from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate{
  public actAsUserId: string;
  public actAsUserId1:string;
  //public user: User = null;
  constructor(public apiService: APIService,
            public router:Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        console.log('inside...'+this.apiService.token)
        if(this.apiService.token) return Promise.resolve(true);
         this.router.navigate(['home']);
        return Promise.resolve(false);


  }

  
}
