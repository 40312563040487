import { Component, OnInit } from '@angular/core';
import { APIService } from '../services/APIService';
import { ngCopy } from 'angular-6-clipboard';
import {ActivatedRoute,Router} from '@angular/router';

@Component({
  selector: 'app-preview-api',
  templateUrl: './preview-api.component.html',
  styleUrls: ['./preview-api.component.css']
})
export class PreviewApiComponent implements OnInit {
 public apiPayLoad:any={payload:''};
 public apiOutput:any;
 public id:any;
  constructor(public apiService: APIService,public activatedRoute:ActivatedRoute,public router:Router) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
  		this.id = params['id'];
  		 this.apiService.getEndPointPayLoad(parseInt(this.id)).subscribe((data:any) => {
     		this.apiPayLoad.payload=JSON.stringify(data.payload,undefined,4);
     	//	this.apiPayLoad.id=data.request.request['_id'];
    		this.apiPayLoad.endPoint=data.request.endPoint;
     		
      });

  		});
    
  }
  copyInput() {
  		ngCopy(this.apiPayLoad.payload);		 
  }
  copyOutput() {
	ngCopy(this.apiOutput);
  }
  callApi() {      
      this.apiService.callApi(this.apiPayLoad.endPoint,this.apiPayLoad.payload).subscribe((data:any) => {
     		this.apiOutput=JSON.stringify(data,undefined,4);
      });
  }
  gotoHome(){
  	this.router.navigate(['/home']);
  }

}
