import { Component, OnInit,ViewChild } from '@angular/core';
import { APIService } from '../services/APIService';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';

import {ActivatedRoute,Router} from '@angular/router';
@Component({
  selector: 'app-create-api',
  templateUrl: './create-api.component.html',
  styleUrls: ['./create-api.component.css']
})
export class CreateApiComponent implements OnInit {
   public apiRequest:any={request:{}};
   public mode:any;
   public appList:any;
   @ViewChild("dbSelectionModal") dbSelectionModal: ModalDialogComponent;
    @ViewChild("multipleColModal") multipleColModal: ModalDialogComponent;
   constructor(public apiService: APIService,public activatedRoute:ActivatedRoute,
   public router:Router) { }
   showMultiColumns:any;
   multipleColumns:any;
   selectedColumns:any;
   selectedColumns1:any = [];

   dbColumns:any;
   dbColumns1:any;
   addRowColsList:any = [];	
	
   addMulitRow() {
     this.multipleColModal.show();
   }
    loadDbColumns() {
      if(this.apiRequest.request.schemaName&&this.apiRequest.request.tableName) {
         let params= {schema:this.apiRequest.request.schemaName,
         				table:this.apiRequest.request.tableName,
                dbName:this.apiRequest.request.dbName,
         				db:this.apiRequest.database,
         				apiType:this.apiRequest.apiType};
	     this.apiService.getDbColumns(params).subscribe(data => {
     					this.dbColumns=data;
     					this.selectedColumns=[];
     					this.dbSelectionModal.show();
     	    	
					}
     	 );
     }
   }  
   submitMulti() {

   		this.processColumns(this.multipleColumns.split(","));
   		this.multipleColModal.hide();

   }
   submitMultiDb() {
   
   for(var i=0;i<this.selectedColumns.length;i++){
   		//this.selectedColumns1.push(this.selectedColumns[i].substring(0,this.selectedColumns[i].indexOf(":")));
      this.selectedColumns1.push(this.dbColumns.columns[this.selectedColumns[i]]);
   }
   	this.processDbColumns(this.selectedColumns1);
   	this.dbSelectionModal.hide();
   }
   processDbColumns(columnlist) {
       for(let i=0;i<columnlist.length;i++) {
          let found=false;
          for(let j=0;j< this.apiRequest.request.columnList.length;j++) {
            if( this.apiRequest.request.columnList[j].dbColumnName==columnlist[i].column_name)
              found=true;
         }
         if(!found) this.apiRequest.request.columnList.push({dbColumnName:columnlist[i].column_name,
                            key:columnlist[i].column_name,type:columnlist[i].data_type,
          defaultSelect:'N',mandatoryFilter:'N',
          canOrderBy:'N',example:columnlist[i].example})
       }
       
       this.showMultiColumns=false;
   }
   processColumns(columnlist) {
       for(let i=0;i<columnlist.length;i++) {
          let found=false;
          for(let j=0;j< this.apiRequest.request.columnList.length;j++) {
          	if( this.apiRequest.request.columnList[j].dbColumnName==columnlist[i])
          	  found=true;
         }
         if(!found) this.apiRequest.request.columnList.push({dbColumnName:columnlist[i],
         										key:columnlist[i],type:'VARCHAR',
    			defaultSelect:'N',mandatoryFilter:'N',
    			canOrderBy:'N'})
       }
       
       this.showMultiColumns=false;
   } 
   deleteAllRow() {
      if(confirm("Do you want to delete all rows"))
        this.apiRequest.request.columnList.length=0;
   }
   deleteRow(column) {
       for(let i=0;i<this.apiRequest.request.columnList.length;i++)
          if(column==this.apiRequest.request.columnList[i]) {
             this.apiRequest.request.columnList.splice(i,1);
             break;
          }
   }
  ngOnInit() {  		
        this.apiService.getAppList().subscribe(params => {
          this.appList=params["list"];
        });
       this.activatedRoute.params.subscribe(params => {
        this.mode=params['mode'];
        if(this.mode!='Create') {
  		 	this.apiService.getEndPointPayLoad(parseInt(params['id'])).subscribe((data:any) => {
  		 	console.log(data.request);
  		 	if(this.mode=='Edit'){  		 	
  		 		this.apiRequest=data.request;
  		 	}else{
  		 	data.request["endPoint"]=data.request["endPoint"].concat("/cloned");
			data.request["id"]=0;
			data.request["reportName"]=data.request["reportName"].concat("_cloned");
			this.apiRequest=data.request;
			}  		 	     	    	
			});
     	}
     	else {
     	   this.apiRequest={request:{ columnList:[]}};
     	}

  		});
  }
  addRow() {
    this.apiRequest.request.columnList.push({type:'VARCHAR',
    			defaultSelect:'N',mandatoryFilter:'N',
    			canOrderBy:'N'})
  }
  validate() {      
      if(!this.apiRequest.reportName){
      	alert("API Name is Required");
      	return  false;
      }
      if(!this.apiRequest.database){
      	alert("Connection is Required");
      	return  false;
      }
      
      if(!this.apiRequest.endPoint) {
      		alert("API End Point is Required");
      		return  false;      	
      }
      
      if(this.mode == 'Create'){
      if(/[^a-zA-Z0-9-/]/.test(this.apiRequest.endPoint) == true){
      		alert("API End Point is should be in proper format");
      		return  false;   
      }
      
      }
      if(!this.apiRequest.apiType){
      	alert("API Type is Required");
      	return  false;
      }
      if(!this.apiRequest.reportDescription){
      	alert("API Description is Required");
      	return  false;
      }
      if(!this.apiRequest.request.tableName){
      	alert("Table/View Name is Required");
      	return  false;
      }
      if(!this.apiRequest.version){
      	alert("Version is Required");
      	return  false;
      }
      if(!this.apiRequest.request.schemaName){
      	alert("Schema is Required");
      	return  false;
      }
      if(!this.apiRequest.request.recordLimit){
      	alert("Record Limit is Required");
      	return  false;
      }
      if(!this.apiRequest.request.dbName){
      	alert("DataBase Name is Required");
      	return  false;
      }      
      
      let canorder=0;
      for(let j=0;j< this.apiRequest.request.columnList.length;j++) {
      	if( this.apiRequest.request.columnList[j].canOrderBy === "true" || this.apiRequest.request.columnList[j].canOrderBy === undefined){
      		alert("Please enter data for can order");
      		return false;
      	}
      	if(! this.apiRequest.request.columnList[j].key){
      		alert("Key cant be null");
      		return false;
      	}
      	if(! this.apiRequest.request.columnList[j].dbColumnName){
      		alert("Db Column Name cant be null");
      		return false;
      	}
      }
      
      alert("all check points passed!");
      return true;
  }
 
  registerApi() {
       if(this.apiRequest.mockApi && this.validate()){
        if(this.mode == 'Create'){
  			this.apiService.validateEndPoint(this.apiRequest).subscribe(data => {
	  		if(data === false){
	  			alert("End Point already available for other API");
	  		}else{
		      	  this.apiService.registerApi(this.apiRequest).subscribe(data => {
		     		//this.apiOutput=JSON.stringify(data,undefined,4);
		     		alert("SuccessFully Updated/Created API");
		     		this.router.navigate(['/home']);
		     	  });
	  		}
	  		});	
 		}else{
 				  this.apiService.registerApi(this.apiRequest).subscribe(data => {
		     		//this.apiOutput=JSON.stringify(data,undefined,4);
		     		alert("SuccessFully Updated/Created API");
		     		this.router.navigate(['/home']);
		     	  });
 		}
       
       }
       else if((this.apiRequest.mockApi == undefined || this.apiRequest.mockApi == false ) && this.validate()){
       	let counterToCheckDups = 0;
       	let counterToCheckInvalid = 0;
       	      //alert(this.apiRequest.request.apiType);
		      if(this.apiRequest.request.schemaName&&this.apiRequest.request.tableName) {
		         let params= {schema:this.apiRequest.request.schemaName,
		         table:this.apiRequest.request.tableName,
             dbName:this.apiRequest.request.dbName,
		         db:this.apiRequest.database,
                apiType:this.apiRequest.apiType     };

          
		         
			     this.apiService.getDbColumns(params).subscribe(data => {
			     this.dbColumns1=data;
			     this.dbColumns = this.dbColumns1.columns;
			     
			     
			     for(let key in this.apiRequest.request.columnList){
			     this.apiRequest.request.columnList[key].isInvalid = false;
			     }
			     
			      for(let key in this.dbColumns){
			     	for(let key2 in this.apiRequest.request.columnList){			     		
			     		if(this.apiRequest.request.columnList[key2].dbColumnName.trim() === this.dbColumns[key].column_name.trim()){
			     			this.apiRequest.request.columnList[key2].isInvalid = true;
			     			
			     		}
			     	}
			     }
			     
			     for(let key in this.apiRequest.request.columnList){
			     	if(this.apiRequest.request.columnList[key].isInvalid === false){
			     		counterToCheckInvalid++;
			     }
			     }
			     if(counterToCheckInvalid > 0){
			      alert("Some of the column(s) not available in Table and added in grid");
			     }else{
 				  this.apiService.registerApi(this.apiRequest).subscribe(data => {
		     		//this.apiOutput=JSON.stringify(data,undefined,4);
		     		alert("SuccessFully Updated/Created API");
		     		this.router.navigate(['/home']);
		     	  });
 		}
				 });
				}
       }
  }
  

}
