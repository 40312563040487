import { Component, OnInit } from '@angular/core';
import { APIService } from '../services/APIService';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PaginationInstance } from '../filter/pagination-instance';
@Component({
    selector: 'app-summary',
    templateUrl: './api-summary.component.html',
    styleUrls: ['./api-summary.component.css']
})
export class ApiSummaryComponent implements OnInit {
    public apiList: any;
    public apiToken: any;
    filter = '';
    maxSize = 9;
    config: PaginationInstance = {
        itemsPerPage: 10,
        currentPage: 1
    };

    constructor(public apiService: APIService, public router: Router, private aroute: ActivatedRoute) {
        console.log('Called Constructor');


    }

    onPageChange(number: number) {
        this.config.currentPage = number;
    }

    deleteAPI(id, endPoint) {
        if (!confirm("Do you want to Delete End Point" + endPoint))
            return;
        this.apiService.deleteAPI(id).subscribe((data: any) => {
            alert("Successfully Delete End Point " + endPoint);
            this.apiList = data.records;
            this.apiService.show = true;
        });
    }
    loadAdmin() {
        this.apiService.getApiList().subscribe((data: any) => {
            this.apiList = data.records;
            this.apiService.show = true;
        });
    }
    loadCatalog() {
        this.apiService.show = true;
        this.router.navigate(['catalog']);
    }
    errorPage() {

    }
    token() {
        this.apiService.getUserInfo().subscribe((data: any) => {
            this.apiService.userInfo = data;
            if (data.admin && data.admin == "F" || data.admin && data.admin == "RO") {
                this.loadAdmin();
            }
            else
                if (data.admin && data.admin == "N") this.loadCatalog();

        });
    }

    tokenFromAuthCode(authCode, redirect_uri, codeChallenge) {
        this.apiService.getTokenByAuthCode(authCode, redirect_uri, codeChallenge).subscribe((data: any) => {
            console.log(data);
            this.apiService.token = data.token;
            this.userData();
        })
    }

    userData() {
        this.apiService.getUserInfo().subscribe((data: any) => {
            this.apiService.userInfo = data;
            if (data.admin && data.admin == "F" || data.admin && data.admin == "RO") {
                this.loadAdmin();
            }
            else
                if (data.admin && data.admin == "N") this.loadCatalog();
        });
    }

    toSessionStorage(itemName, value) {
        sessionStorage.setItem(itemName, value);
    }

    fromSessionStorage(itemName) {
        return sessionStorage.getItem(itemName);
    }

    ngOnInit() {
        if (this.apiService.token) {
            this.token();
            return;
        }
        if (location.href.indexOf('code') == -1) {
            let loc = location.href.split('/');
            let redirectUrl = loc[0] + "//" + loc[2];
            this.apiService.getAuthNTokenUrls().subscribe((data: any) => {
                this.apiService.authTokenUrl = data.tokenUrl;
                this.toSessionStorage('codeVerifier', data.codeVerifier);
                window.location.href = data.authCodeUrl + "&redirect_uri=" + redirectUrl;
            });
        }
        else {
            let ref = location.href;
            ref = ref.substring(ref.indexOf('code') + 5);
            let loc = location.href.split('/');
            let redirectUrl = loc[0] + "//" + loc[2];
            if (ref.indexOf("&") != -1) ref = ref.substring(0, ref.indexOf("&"));
            this.apiService.authCode = ref;
            console.log(ref);
            this.tokenFromAuthCode(this.apiService.authCode, redirectUrl, this.fromSessionStorage('codeVerifier'));
        }

    }

}
