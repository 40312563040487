import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CreateApiComponent } from './create-api/create-api.component';
import { ApiSummaryComponent } from './api-summary/api-summary.component';
import { SwaggerUiComponent } from './swagger-ui/swagger-ui.component';
import { AuthGuard } from './services/AuthGuard';

import { PreviewApiComponent } from './preview-api/preview-api.component';
const appRoutes: Routes = [
 
  {
    path: 'request/:mode/:id',
    component: CreateApiComponent,
     canActivate: [AuthGuard]
  },
  {
    path: 'previewRequest/:id',
    component: PreviewApiComponent,
     canActivate: [AuthGuard]
  },
  {
     path: 'catalog',
     redirectTo: 'catalog/',
     pathMatch: 'full',
     canActivate: [AuthGuard]
  },
  {
     path: 'catalog/:id',
     component: SwaggerUiComponent,
     canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: ApiSummaryComponent
  },
   {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
