<div class="row hideScroll">
<div class="col-lg-6">
<div class="form-horizontal">
<table style="width: 100%">
<tr>
	<td>
		<b>Request Payload:</b>
	</td>
</tr>
<tr>
	<td align=right>
		<button type="button" class="btn btn-primary" (click)="copyInput()"
								style="margin-right: 5px">Copy</button>
	</td>
</tr>
<tr>
	<td colspan=2>
		<textarea [(ngModel)]="apiPayLoad.payload" id="inputs" style="width:100%;height: 500px;" ></textarea>
	</td>
</tr>
</table>
</div>
</div>
<div class="col-lg-6">
<div class="form-horizontal">
<table style="width: 100%">
<tr>
	<td>
		<b>Response Payload:</b> 
	</td>
	<td>
		<div id="time"></div>
	</td>
</tr>
<tr>
	<td align=right>
    <button type="button" class="btn btn-primary" (click)="callApi()"
								style="margin-right: 5px">Test</button>
	<button type="button" class="btn btn-primary" (click)="gotoHome()"
								style="margin-right: 5px">Cancel</button>
	<button type="button" class="btn btn-primary" (click)="copyOutput()"
								style="margin-right: 5px">Copy</button>
	</td>
</tr>
<tr>
	<td colspan=3>
		<textarea [(ngModel)]="apiOutput" id="outputs" style="background:lightgrey;width:100%;height: 500px;overflow-y: scroll;" readonly></textarea>
	</td>
</tr>
</table>
</div>
</div>
</div>

<app-swagger-ui ></app-swagger-ui>
