import { Component } from '@angular/core';
import { APIService } from './services/APIService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'catalog-app';
  public show:any;
  constructor(public apiService: APIService) { }
}
