import { Component, Input } from "@angular/core";
import { APIService } from '../services/APIService';

@Component({
selector: "app-modal-dialog",
templateUrl: "./modal-dialog.component.html",
styleUrls: ["./modal-dialog.component.css"],
}) 
export class ModalDialogComponent {
public visible = false;
public visibleAnimate = false;


@Input()
title: string;


public show(): void {
this.visible = true;
 
setTimeout(() => (this.visibleAnimate = true), 100);
} 
public hide(): void {
this.visibleAnimate = false;
setTimeout(() => (this.visible = false), 300);
}
public onContainerClicked(event: MouseEvent): void {
if ((<HTMLElement>event.target).classList.contains("modal")) {
this.hide();
}
}
}  