<div >
	<div class="row">
		<div class="col-lg-12">

			<div class="form-horizontal">

				<!--
<table width=100%><tr><td><b>Request Payload:</b></td>
			<td align=right>
			<a  routerLink="/home" class="btn btn-primary" >Cancel</a>
			&nbsp;&nbsp;
			<input type=button value="Register" class="btn btn-primary" onclick="register()">
		   </td>
		</tr>
		<tr><td colspan=2><textarea  [(ngModel)]="apiDefinition" id="apiDefinition" style="width:100%;height: 500px;"></textarea></td>
		</tr>
</table>
-->
			
				<table width=90%  class="table table-sm">
					<tr>
						<td align="right">API Category<span style="color: red;">*</span>:</td>
						<td width="35%"><input type="text"
							[(ngModel)]="apiRequest.category" class="form-control" required></td>
						<td align="right">Application Name<span style="color: red;">*</span>:</td>
						<td  width="35%"><select class="form-control" multiple="multiple" [(ngModel)]="apiRequest.requesterApp">
								<option *ngFor="let appName of appList;let i=index" [value]="appName.requestor_app" >{{appName.requestor_app}}</option>
								</select></td>
					</tr>
					<tr>
						<td align="right">API Name<span style="color: red;">*</span>:</td>
						<td><input type="text"
							[(ngModel)]="apiRequest.reportName" class="form-control" name="apiName" required></td>
						<td align="right">Connection<span style="color: red;">*</span>:</td>
						<td><select class="form-control" [(ngModel)]="apiRequest.database">
									<option>AURORA</option>
									<option >SNOWFLAKE</option></select></td>
					</tr>
					<tr>
						<td align="right">API EndPoint<span style="color: red;">*</span>:</td>
						<td><input type="text" class="form-control"
							[(ngModel)]="apiRequest.endPoint" [readonly]="mode=='Edit'"></td>
						<td align="right">API Type<span style="color: red;">*</span>:</td>
						<td><select class="form-control" [(ngModel)]="apiRequest.apiType">
								<option>READ</option>
								<option>UPDATE</option>								
						</select></td>
					</tr>
					<tr>
						<td align="right">API Description<span style="color: red;">*</span>:</td>
						<td><textarea class="form-control" rows="5" [(ngModel)]="apiRequest.reportDescription"></textarea></td>
						<td align="right">Table/View Name<span style="color: red;">*</span>:</td>
						<td><tr><td><input type="text" class="form-control"
							[(ngModel)]="apiRequest.request.tableName"></td>
						<td><button type="button" class="btn btn-primary" (click)="loadDbColumns()"
								style="margin-right: 5px">Load DB Columns</button></td></tr></td>
							
					</tr>
					<tr>
						<td align="right">Version<span style="color: red;">*</span>:</td>
						<td><input type="text" class="form-control"
							[(ngModel)]="apiRequest.version"></td>
						<td align="right">Schema Name<span style="color: red;">*</span>:</td>
						<td><input type="text" class="form-control"
							[(ngModel)]="apiRequest.request.schemaName"></td>
							
						
					</tr>
					<tr>						
						<td align="right">Record Limit<span style="color: red;">*</span>:</td>
						<td><input type="text" class="form-control"
							[(ngModel)]="apiRequest.request.recordLimit"></td>
						<td align="right">DataBase Name<span style="color: red;">*</span>:</td>
						<td><input type="text" class="form-control"
							[(ngModel)]="apiRequest.request.dbName"></td>	
					</tr>
				<!--	<tr>						
						
						<td align="right"></td>
						<td></td>	
					</tr>-->
					<tr>
						<td colspan=1 >
							 <a href="#" class="btn btn-info" [routerLink]="['/catalog',apiRequest.id]">Preview</a>
						</td>
						<td colspan="3" align="right">
							<input type=checkbox style="margin-right: 5px"
							   [(ngModel)]="apiRequest.mockApi"
							 >Mock API
							
							<button type="button" class="btn btn-info"
								style="margin-right: 5px" (click)="addRow()">Add</button>
							<button type="button" class="btn btn-info"
								style="margin-right: 5px" (click)="addMulitRow()">Add(+)</button>
						    <button type="button" class="btn btn-primary"
								style="margin-right: 5px" (click)="validate()">Validate</button>
								<div *ngIf="apiService.userInfo.admin=='F'">
							<button type="submit" class="btn btn-success"
								style="margin-right: 5px" (click)="registerApi()">Submit</button>
								</div>
								</td>
					</tr>
				</table>
			
			
				
				<table width=90% *ngIf="apiRequest.request.columnList"
					class="table table-sm table-striped table-bordered">
					<tr>
						<th>Serial No.</th>
						<th>API Key Name</th>
						<th>Column Name</th>
						<th>Data Type</th>
						<!-- <th *ngIf="apiRequest.apiType=='UPDATE'">Mandatory Field</th> -->
						<th *ngIf="apiRequest.apiType=='READ'">Group By</th>
						<th *ngIf="apiRequest.apiType=='READ'">Mandatory Filter</th>
						<th *ngIf="apiRequest.apiType=='UPDATE'">Mandatory Field</th>
						<th *ngIf="apiRequest.apiType=='READ'">Filter (Default Value)</th>
						<th *ngIf="apiRequest.apiType=='READ'">Default Select</th>
						<th *ngIf="apiRequest.apiType=='READ'">Can Order</th>
						<th >Example</th>
						<th><span class="glyphicon glyphicon-trash" title="Delete" (click)="deleteAllRow()"></span></th>
					</tr>
					<tr *ngFor="let column of apiRequest.request.columnList;index as i">
						<td>{{ i + 1}}</td>
						<td>
						<input type="text" class="form-control"
							[(ngModel)]="column.key">							
							<span [ngStyle]="{'display':column.isInvalid === true || column.isInvalid === undefined?'none':'block'}" style="color: red">Invalid Column Name</span></td>
						<td><input type="text" class="form-control"
							[(ngModel)]="column.dbColumnName"></td>
						<td><select class="form-control"  [(ngModel)]="column.type">
								<option>VARCHAR</option>
								<option>DECIMAL</option>
								<option>BIGINT</option>
								<option>INTEGER</option>
								<option>DATE</option>
								<option>TIMESTAMP</option>
						</select></td>
						<!-- Added by rthenge for WB API -->
						<!-- <td *ngIf="apiRequest.apiType=='UPDATE'">
							<select class="form-control"
						 [(ngModel)]="column.mandatoryField">
							<option value="N">No</option>
								<option value="Y">Yes</option></select></td>-->
						<!-- End -->
						<td *ngIf="apiRequest.apiType=='READ'">
							<select class="form-control" [(ngModel)]="column.groupBy">
								<option value="">No</option>
								<option value="Y">Yes</option>
						</select></td>
						<td>
							<select class="form-control"
						 [(ngModel)]="column.mandatoryFilter">
							<option value="N">No</option>
								<option value="Y">Yes</option></select></td>
						<td *ngIf="apiRequest.apiType=='READ'"><input type="text" class="form-control" [(ngModel)]="column.defaultValues"></td>
						<td *ngIf="apiRequest.apiType=='READ'"><select class="form-control" [(ngModel)]="column.defaultSelect">
							<option value="N">No</option>
							<option value="Y">Yes</option>
							<option value="SUM">SUM</option>
							<option value="MIN">MIN</option>
							<option value="MAX">MAX</option>
							<option value="AVG">AVG</option>
							<option value="COUNT">COUNT</option>
							<option value="COUNT DISTINCT">COUNT DISTINCT</option>
						</select></td>
						
						<td *ngIf="apiRequest.apiType=='READ'"><select class="form-control" [(ngModel)]="column.canOrderBy">
							<option value="N">No</option>
								<option value="Y">Yes</option></select></td>
						<td><input type="text" class="form-control"
							[(ngModel)]="column.example"></td>
						<td><span class="glyphicon glyphicon-trash" title="Delete" (click)="deleteRow(column)"></span></td>
					</tr>

				</table>

			</div>
		</div>
	</div>
</div>

<app-modal-dialog title="Column List" class="settings-modal" #dbSelectionModal>
<div class="app-modal-body" style="margin: 0px auto;height:250px;width:520px">
<div *ngIf="dbColumns&&dbColumns.columns">
	<select [(ngModel)]="selectedColumns" multiple="multiple" class="form-control" style="height: 175px">
	<option *ngFor="let col of dbColumns.columns; let i=index"  [value]="i">{{col.column_name}} : {{col.data_type}}</option>
	</select>
	
				
	</div>
</div>
<div class="app-modal-footer">
	<button type="button" class="btn btn-success" (click)="submitMultiDb()"
								style="margin-right: 5px">Submit</button>

		<button  class="btn btn-success" (click)="dbSelectionModal.hide()">Close</button>
</div>
</app-modal-dialog>


<app-modal-dialog title="Column List" class="settings-modal" #multipleColModal>
<div class="app-modal-body">
	<div >Enter Column Names with Comma Separated<br>
				<textarea cols=20 rows=10 [(ngModel)]="multipleColumns"></textarea>
					
				</div> 
</div>
<div class="app-modal-footer">
<button type="button" class="btn btn-success"
		style="margin-right: 5px" (click)="submitMulti()">Submit</button>
<button (click)="multipleColModal.hide()" class="btn btn-success">Close</button>
</div>
</app-modal-dialog>