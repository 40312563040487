import { Injectable, EventEmitter, ComponentFactoryResolver } from "@angular/core";
import { HttpHeaders,HttpClient,HttpResponse } from "@angular/common/http";


import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";

@Injectable()
export class APIService {
  public location='/';
  public authCode:any;
  public authTokenUrl:any;
  public token:any;
  public show:any=false;
  public userInfo:any;
  public apiVersion='v2';
  constructor(private http: HttpClient) {
    if(window.location.href.indexOf("localhost:4200")!=-1)
      this.location= 'http://localhost:8080/'
  }
  headers() {
    let header=new HttpHeaders()
    header=header.set('Content-Type','application/json')
    header=    header.set(     'Accept', 'application/json')
    header=    header.set( 'Authorization','Bearer '+this.token)
   //   header=    header.set( 'csdf-token',this.token)
   //   header=    header.set( 'Access-Control-Allow-Origin', '*')
     return header;
  }
   getSwaggerCatalog(id,clientId) {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/catalog'+(id ? '/'+id : '')
    if(clientId)
      URL = URL+"?clientId="+clientId;
     return this.http.get(URL,  {
      withCredentials: true,
      headers: this.headers()
    }).map(res => res);
  }
  getAuthNTokenUrls() {
      var URL = this.location+'rest/adminService/' + this.apiVersion + '/getAuthNTokenUrls';
      return this.http.get(URL,  {
       headers: this.headers()
     }).map(res => res);
   }
  getOAuthUrl() {
     var URL = this.location+'rest/adminService/v1/getOAuthUrl';
     return this.http.get(URL,  {    
      headers: this.headers()
    }).map(res => res);
  }
  getUserInfo() {
     var URL = this.location+'rest/adminService/' + this.apiVersion + '/getUserInfo';
     return this.http.get(URL,  {
    
      headers: this.headers()
    }).map(res => res);
  }
   getToken(clientId,clientSecret) {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/getToken';

     return this.http.post(URL, {clientId:clientId,clientSecret:clientSecret}, {
      withCredentials: true,
      headers: this.headers()
    }).map(res => res);
  }
  getTokenByAuthCode(authCode, redirect_uri, codeChallenge) {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/getTokenByAuthCode';
    return this.http.post(URL + "?code=" + this.authCode + "&redirect_uri=" + redirect_uri + "&codeChallenge=" + codeChallenge, null, {
          withCredentials: true,
          headers: this.headers()
        }).map(res => res);
      }
   getAppList() {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/appList';

     return this.http.post(URL, {}, {
      withCredentials: true,
      headers: this.headers()
    }).map(res => res);
  }
  
  getApiList() {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/apiList';

     return this.http.post(URL, {}, {
      withCredentials: true,
      headers: this.headers()
    }).map(res => res);
  }
   getEndPointPayLoad(id) {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/getEndPointPayLoadById';
    
     return this.http.post(URL, {id:id}, {
     withCredentials: true,
      headers: this.headers()
    }).map(res => res);
  }

  callApi(endPoint,json) {
    var URL = this.location+'rest/builderService/'+endPoint;

     return this.http.post(URL, json, {
      withCredentials: true,
      headers: this.headers()
    }).map(res => res);
  }
   registerApi(params) {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/registrationRequest';

     return this.http.post(URL, params, {
      withCredentials: true,
      headers: this.headers()
    }).map(res => res);
  }
  deleteAPI(id) {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/registrationRequest/'+id;

     return this.http.delete(URL,  {
      withCredentials: true,
      headers: this.headers()
    }).map(res => res);
  }
  getDbColumns(params) {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/getTableColumns';

     return this.http.post(URL,params, {
      withCredentials: true,
      headers: this.headers()
    }).map(res => res);
  }
  
    validateEndPoint(params) {
    var URL = this.location+'rest/adminService/' + this.apiVersion + '/validateEndPoint';
     return this.http.post(URL, params, {
      withCredentials: true,
      headers: this.headers()
    }).map(res => res);
    
  }

  

}
