<style>
.swagger-ui .info {
  margin: 10px 0
}
.rightSideDiv {
    float : right;
    padding-right: 200px;
 }
</style>
<div class="rightSideDiv">
	<input type=button value="OAuth Token"  class="btn btn-success" (click)="showTokenWindow()">
</div>
<div id="swagger-ui"></div>


<app-modal-dialog title="OAuth Details" class="settings-modal" #dbSelectionModal>
<div class="app-modal-body" style="margin: 0px auto;height:250px;width:520px">
      <table class="table">
      	<tr *ngIf="showError"><td colspan=2><span style="color:red">Invalid Client Id or Client Secret</span></td></tr>
      	<tr><td>Client Id:</td><td><input type="text" [(ngModel)]="clientId"> </td></tr>
      	<tr><td>Client Secret:</td><td><input type="password" [(ngModel)]="clientSecret"> </td></tr>
      	<tr><td colspan=2 align="center">
      		<input type="button" value="Submit" class="btn btn-success" (click)="generateToken()"> &nbsp;
      	<input type="button" value="Close" class="btn btn-info" (click)="dbSelectionModal.hide()">
      		&nbsp; <input type="button" value="Reset" class="btn btn-info" (click)="clearToken()">  </td></tr>
      </table>
</div>
</app-modal-dialog>